/*Base Styles*/

html {
  height: 100%;
}

:root {
  font-size: 16px;
}

body {
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: $font-weight-base;
  }
  height: 100%;
  overflow: hidden;
}

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important;
}

#nprogress .bar {
  z-index: 5000 !important;
  background: $secondary !important;
}

#nprogress .peg {
  @include box-shadow(0 0 10px $secondary, 0 0 5px $secondary !important);
}

#nprogress .spinner {
  z-index: 5000 !important;
}

#nprogress .spinner-icon {
  border-top-color: $secondary !important;
  border-left-color: $secondary !important;
}

.ripple-effect {
  @extend %ripple-effect;
}

.text-transform-none {
  text-transform: none;
}

.br-break {
  word-break: break-all !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.z-index-20 {
  z-index: 20;
}

.size-120 {
  @extend %size-120;
}

.size-100 {
  @extend %size-100;
}

.size-90 {
  @extend %size-90;
}

.size-80 {
  @extend %size-80;
}

.size-70 {
  @extend %size-70;
}

.size-60 {
  @extend %size-60;
}

.size-50 {
  @extend %size-50;
}

.size-40 {
  @extend %size-40;
}

.size-30 {
  @extend %size-30;
}

.size-30 {
  @extend %size-30;
}

.size-20 {
  @extend %size-20;
}

.size-10 {
  @extend %size-10;
}

.size-8 {
  @extend %size-8;
}

.cursor-pointer {
  cursor: pointer;
}

button:focus {
  outline: none !important;
}
