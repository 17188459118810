.page-heading {
  .breadcrumb-custom {
    padding-top: 15px;

    .MuiBreadcrumbs-li {
      a {
        font-size: 14px;
        color: #282828;
      }

      [active='true'] {
        font-size: 14px;
        color: #0a5379;
        text-decoration: none;
      }

      & :hover {
        color: #0a5379;
      }
    }

    padding-bottom: 35px;
    z-index: 2;

    @media screen and (max-width: 575px) {
      margin-bottom: 18px;
      padding: 16px 20px;
    }

    & .title {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 26px;
    }
  }

  .MuiBreadcrumbs-separator .MuiSvgIcon-root {
    margin-bottom: -5px;
    font-size: 14px;
    color: #ed901a;
  }
}
