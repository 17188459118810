.confirm-loading-root {
  min-width: 160px;
  min-height: 160px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}
.confirm-loading-item {
  width: 60px;
  height: 60px;
  color: #fff;
}
.confirm-no-root > :nth-child(3) > div {
  background: unset;
  box-shadow: none;
}
.confirm-root {
  min-width: 400px;
  min-height: 200px;
  border-radius: 118px;
  padding: 8px;
}
.confirm-head > :nth-child(1) {
  display: flex;
  align-items: center;
}
.confirm-typo-head {
  color: #25282b;
  margin-left: 0px;
  font-size: 16px;
}
.confirm-content {
  min-height: 62px;
  margin-bottom: 15px;
}
.confirm-content .confirm-message {
  color: #637381;
}
