.pagination-custom {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  border: 0.5px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 6px;

  .arrowButton {
    min-width: 16px;
    padding: 3px;
  }

  .MuiIconButton-root .MuiSvgIcon-root {
    color: #ed901a;
  }

  .MuiIconButton-root[disabled] .MuiSvgIcon-root {
    color: #bdbdbd;
  }

  .paginationNumber.MuiButton-textPrimary {
    color: #0a5379;
  }
}
