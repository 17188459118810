/*Home Dashboard Styles*/
.container-content {
  background-color: #0a5379 !important;
  box-shadow: none !important;
  padding: 50px 40px;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: 500px;
      font-size: 32px;
      color: #ffffff;
    }
    h4 {
      font-weight: normal;
      font-size: 18px;
      color: #ffffff;
    }
    .btn {
      background: #ed901a;
      border-radius: 8px;
      border: none;
      padding: 11px 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
  }
}

.list-booking-home {
  border-radius: 8px !important;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24),
    0px 16px 32px -4px rgba(145, 158, 171, 0.24) !important;

  .card-content {
    padding: 26px;
  }

  .title-card-list-booking {
    font-size: 20px;
    font-weight: 500;
  }

  .footer-card-list-booking {
    display: flex;
    justify-content: flex-end;
    padding: 24px;

    .view-all {
      cursor: pointer;

      .text-footer-card {
        font-size: 16px;
        font-weight: 500;
        display: inline;
        margin-right: 10px;
      }
    }
  }

  .MuiTableHead-root {
    .MuiTableCell-head {
      background-color: #e9e9e9;
      border-bottom: none;
    }

    .MuiTableCell-head:first-child {
      border-radius: 8px 0 0 8px;
    }

    .MuiTableCell-head:last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root:last-child {
      .MuiTableCell-body {
        border-bottom: none;
      }
    }
  }
}
