.text-editor-wrap {
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  margin-top: 15px;

  &.border-error {
    border: 1px solid #f44336;
  }
  .ql-editor {
    min-height: 15em;
    padding: 5px;
  }
  .ql-container.ql-snow {
    border: 0;
  }
  .ql-toolbar.ql-snow {
    border: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 5px;
  }
  .label-text {
    position: absolute;
    top: -10px;
    left: 12px;
    font-size: 12px !important;
    color: rgb(139, 137, 137);
    background-color: #fff;
    padding: 0 4px;

    &.text-error {
      color: #f44336;
    }
  }
}
.helper-text {
  color: #f44336;
  margin-left: 15px;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  font-family: 'Be Vietnam', sans-serif;
  line-height: 1em;
}
