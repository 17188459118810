.auth-page {
  position: relative;
  overflow: auto;

  .bg-item-left {
    position: absolute;
    left: 0;
    bottom: 50px;
  }
  .bg-item-right {
    position: absolute;
    right: 0;
    top: 50px;
  }

  .padding-vertical {
    padding: 100px 36px !important;
  }

  .card {
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 60px 36px;
    margin-bottom: 165px;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #25282b;
    }

    .title-desc {
      & h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #0a5379;
      }
      & p {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding: 0 50px;
      }
      & .mb-30 {
        margin-bottom: 40px;
      }
    }

    .forgot-password-text {
      font-weight: 500;
      font-size: 16px;
      text-align: right;
      cursor: pointer;
      & a {
        color: #282828;
      }
    }

    .btn-wrap {
      text-align: center;

      .button {
        background: #ed901a;
        border-radius: 6px;
        text-transform: capitalize;
        border: none;
        color: #ffffff;
        padding: 11px 20px;
        margin-top: 40px;
        width: 145px;
      }
      .button-disabled {
        background: #e7e7e7;
        border-radius: 6px;
        text-transform: capitalize;
        border: none;
        color: #ffffff;
        padding: 11px 20px;
        margin-top: 40px;
        width: 145px;
      }
      .button-back {
        background: #ed901a;
        border-radius: 6px;
        text-transform: capitalize;
        border: none;
        color: #ffffff;
        padding: 11px 20px;
        margin-top: 40px;
      }
    }

    .offer {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #282828;
      margin-top: 16px;

      & a {
        color: #0a5379;
      }
    }
  }
}

.header-auth {
  padding: 80px 0;
  text-align: center;
}

.footer-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;

  .img {
    margin-right: 20px;
  }

  .footerBottom {
    margin-top: 32px;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    color: #282828;
  }
}
