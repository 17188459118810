.table-custom {
  width: 100%;
  box-shadow: 0px 0px 2px 1px rgba(145, 158, 171, 0.02),
    0px 16px 32px -4px rgba(145, 158, 171, 0.24) !important;

  .table-parent {
    min-width: 800px;
  }
  .tableWrapper {
    overflow-x: auto;
    padding: 0px 26px;
  }
  .tableBody .MuiTableRow-root:last-child .MuiTableCell-body {
    border-bottom: none;
  }
  .cellCheckbox {
    width: 62px;
    text-align: center;
    border-bottom: 1px solid #f5f5f5;
  }
  .rowBody {
    background: #fff;
    height: 50px;
  }
  .rowBody:hover {
    background: #f5f5f5;
  }
  .checkBoxIcon > span > svg {
    width: 0.9em;
    height: 0.9em;
  }
  .cellBody {
    padding: 0px 12px;
    color: #5d5d5d;
    // cursor: pointer;
    border-bottom: 1px solid #f5f5f5;
    position: relative;
  }
  .cellBody:first-child {
    padding-left: 24px;
  }
  .input-parent {
    appearance: none;
    width: 82%;
    border: none;
    margin: 12px 0;
    padding: 0px 26px 0px 10px;
    height: 28px;
    background: #f2f2f2;
    border-radius: 5px;
  }
  .closeIcon {
    position: absolute;
    right: 8px;
    top: 17px;
    margin-right: 17%;
    font-size: 18px !important;
  }
  .disableInput {
    background: #bdbdbd;
  }
  .date-filter {
    width: 82%;
    padding: 0 10px;
    margin-right: 20px;
  }
}

.table-toolbar-custom {
  padding-right: 6px;

  &.highlight {
    color: #000;
    background: rgba(0, 0, 0, 0.04);
  }
  .wrap-toolbar {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  .actions-toolbar {
    display: flex;
    align-items: center;
  }
  .search {
    width: 100%;

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }
    .MuiInputBase-root {
      input::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #828282;
      }
    }
    .MuiInputAdornment-root {
      .MuiSvgIcon-root {
        color: #ed901a;
      }
    }
  }
  .action-btn {
    background: #ed901a;
    border-radius: 6px;
    padding: 7px 18px;
    border: none;
    color: #ffffff;
    font-size: 12px;
  }
}

.table-head-custom {
  .cellCheckbox-head {
    width: 62px;
    text-align: center;
  }
  .checkBoxIcon > span > svg {
    width: 0.9em;
    height: 0.9em;
  }
  .cellHead {
    color: #25282b;
    font-size: 14px;
    font-weight: 600;
  }
  .tableCellHead {
    background-color: #e9e9e9;
    border-bottom: none !important;
  }
  .tableCellHead:first-child {
    padding-left: 24px !important;
    border-radius: 8px 0 0 8px !important;
  }
  .tableCellHead:last-child {
    border-radius: 0px 8px 8px 0px !important;
  }
}

.table-footer-custom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;

  .rows-per-page {
    display: flex;
    align-items: center;
  }
  .selects {
    margin: 0 12px;
    color: #5d5d5d;
    font-size: 0.9rem;
  }
  .typography {
    color: #5d5d5d;
    font-size: 0.9rem;
  }
}
