.board-root {
  background: #fff;
  min-height: 50px;
  border-radius: 8px;
  padding: 26px;
  box-shadow: 0px 0px 2px 1px rgba(145, 158, 171, 0.02),
    0px 16px 32px -4px rgba(145, 158, 171, 0.24) !important;
}

.board-action-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 26px;
}

.board-label {
  text-transform: capitalize !important;
}

.action-wrap {
  padding-left: 10px;
}
