.my-profile {
  .logo {
    width: 300px;
    height: 300px;
    margin-bottom: 26px;
    box-shadow: 0px 2px 1px rgba(145, 158, 171, 0.02),
      0px 16px 32px -4px rgba(145, 158, 171, 0.24);
  }

  .title {
    font-size: 32px;
    font-weight: 700;
  }

  .detail {
    .title-detail {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 26px;
    }

    .detail-list {
      margin-bottom: 16px;

      .detail-title {
        color: #898989;
        font-size: 14px;
      }

      .detail-value {
        color: #25282b;
        font-size: 14px;
      }
    }
  }
}
