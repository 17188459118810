.gallery-component {
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #282828;
    margin-bottom: 12px;
  }

  .container-images {
    display: flex;
    flex-direction: row;

    .wrap-img {
      width: 160px;
      height: 170px;
      border-radius: 10px;
      border: 1px solid #bdbdbd;
      margin-right: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
      }

      .empty-img {
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
.ReactModal__Overlay {
  z-index: 1250 !important;
}
