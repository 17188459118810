.appointment-page {
  .section {
    margin-bottom: 60px;

    .date {
      font-weight: 500;
      font-size: 14px;
      color: #696969;
    }
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 42px;
      color: #282828;
    }
    .sub-title {
      font-weight: 500;
      font-size: 16px;
      color: #282828;
    }
  }

  .action-btn {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
