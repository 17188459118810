/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $headings-font-weight;
  margin: 0 0 $headings-margin-bottom;
  color: $headings-color;
}

[class*='text-'] h1,
[class*='text-'] .h1 {
  color: inherit;
}

[class*='text-'] h2,
[class*='text-'] .h2 {
  color: inherit;
}

[class*='text-'] h3,
[class*='text-'] .h3 {
  color: inherit;
}

[class*='text-'] h4,
[class*='text-'] .h4 {
  color: inherit;
}

[class*='text-'] h5,
[class*='text-'] .h5 {
  color: inherit;
}

[class*='text-'] h6,
[class*='text-'] .h6 {
  color: inherit;
}

.text-strikethrough {
  text-decoration: line-through !important;
}

.desc-list {
  margin-bottom: 0;
  li {
    padding: $jr-card-padding;
    border-bottom: 1px solid $gray-500;
    margin-left: -$jr-card-margin;
    margin-right: -$jr-card-margin;

    &:last-child {
      border-bottom: 0 none;
    }

    & .card-title {
      margin-bottom: 6px;
    }
  }

  &.even-odd {
    li {
      border-bottom: 0 none;

      &:nth-child(2n) {
        background-color: $gray-300;
      }
    }
  }
}
