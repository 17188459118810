.exhibitor-page {
  .MuiTabs-flexContainer {
    .MuiTab-root {
      text-align: left !important;
    }
    .MuiTab-wrapper {
      text-transform: capitalize;
    }
    .Mui-selected {
      color: #0a5379;
    }
  }

  .section {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }

    .date {
      font-weight: 500;
      font-size: 14px;
      color: #696969;
    }
    .title {
      font-weight: 500;
      font-size: 20px;
      color: #282828;
    }
  }

  .action-btn {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
